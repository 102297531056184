/*!
 * Bootstrap v4.5.0 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE) */
:root {
  --blue:#007bff {}
  --indigo:#6610f2 {}
  --purple:#6f42c1 {}
  --pink:#e83e8c {}
  --red:#dc3545 {}
  --orange:#fd7e14 {}
  --yellow:#ffc107 {}
  --green:#28a745 {}
  --teal:#20c997 {}
  --cyan:#17a2b8 {}
  --white:#ffffff {}
  --gray:#869ab8 {}
  --gray-dark:#384c74 {}
  --primary:#335eea {}
  --secondary:#506690 {}
  --success:#42ba96 {}
  --info:#7c69ef {}
  --warning:#fad776 {}
  --danger:#df4759 {}
  --light:#f9fbfd {}
  --dark:#1b2a4e {}
  --primary-desat:#6c8aec {}
  --black:#161c2d {}
  --white:#ffffff {}
  --breakpoint-xs:0 {}
  --breakpoint-sm:576px {}
  --breakpoint-md:768px {}
  --breakpoint-lg:992px {}
  --breakpoint-xl:1200px {}
  --font-family-sans-serif:-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" {}
  --font-family-monospace:SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace {} ; }

*, ::after, ::before {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(22, 28, 45, 0); }

article, footer, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: HKGroteskPro, serif;
  font-size: 1.0625rem;
  font-weight: 400;
  line-height: 1.6;
  color: #161c2d;
  text-align: left;
  background-color: #fff; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

 ul {
  margin-top: 0;
  margin-bottom: 1rem; }

 ul ul {
  margin-bottom: 0; }

small {
  font-size: 80%; }

a {
  color: #335eea;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #133bbd;
    text-decoration: underline; }
  a:not([href]) {
    color: inherit;
    text-decoration: none; }
    a:not([href]):hover {
      color: inherit;
      text-decoration: none; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 1.5rem 2rem;
  padding-bottom: 1.5rem 2rem;
  color: #869ab8;
  text-align: left;
  caption-side: bottom; }

button {
  border-radius: 0;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }
  button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color; }

input {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button, input {
  overflow: visible; }

button {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

[type="button"], [type="submit"], button {
  -webkit-appearance: button; }

[type="button"]:not(:disabled), [type="submit"]:not(:disabled), button:not(:disabled) {
  cursor: pointer; }

[type="button"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner, button::-moz-focus-inner {
  padding: 0;
  border-style: none; }

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }
  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

[hidden] {
  display: none !important; }

.h1, .h2, .h3, .h4, .h6, h1, h2, h3, h4, h6 {
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1; }

.h1, h1 {
  font-size: 2.3125rem; }

.h2, h2 {
  font-size: 1.9375rem; }

.h3, h3 {
  font-size: 1.3125rem; }

.h4, h4 {
  font-size: 1.1875rem; }

.h6, h6 {
  font-size: 0.75rem; }

.lead {
  font-size: 1.3125rem;
  font-weight: 400; }

.display-2 {
  font-size: 3.8125rem;
  font-weight: 400;
  line-height: 1; }

.display-3 {
  font-size: 3rem;
  font-weight: 400;
  line-height: 1; }

.display-4 {
  font-size: 2.6875rem;
  font-weight: 400;
  line-height: 1; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #f1f4f8; }

.small, small {
  font-size: 88.2%;
  font-weight: 400; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.container, .container-fluid {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container {
    max-width: 1040px; } }

@media (min-width: 576px) {
  .container {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container {
    max-width: 1040px; } }

.row {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
  margin-left: -20px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
   .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

 .col-10, .col-12, .col-4, .col-6, .col-8, .col-auto, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-6, .col-lg-7, .col-md, .col-md-10, .col-md-12, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-auto, .col-xl-10, .col-xl-11, .col-xl-2, .col-xl-4, .col-xl-6 {
  position: relative;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px; }

.col-auto {
  -webkit-box-flex: 0;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-4 {
  -webkit-box-flex: 0;
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-6 {
  -webkit-box-flex: 0;
  flex: 0 0 50%;
  max-width: 50%; }

.col-8 {
  -webkit-box-flex: 0;
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-10 {
  -webkit-box-flex: 0;
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-12 {
  -webkit-box-flex: 0;
  flex: 0 0 100%;
  max-width: 100%; }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    -webkit-box-flex: 1;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .col-md-auto {
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-4 {
    -webkit-box-flex: 0;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    -webkit-box-flex: 0;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -webkit-box-flex: 0;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    -webkit-box-flex: 0;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-10 {
    -webkit-box-flex: 0;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-12 {
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-1 {
    -webkit-box-ordinal-group: 2;
    order: 1; }
  .order-md-2 {
    -webkit-box-ordinal-group: 3;
    order: 2; }
  .offset-md-4 {
    margin-left: 33.33333%; } }

@media (min-width: 992px) {
  .col-lg-2 {
    -webkit-box-flex: 0;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    -webkit-box-flex: 0;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -webkit-box-flex: 0;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-6 {
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -webkit-box-flex: 0;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-12 {
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-lg-0 {
    margin-left: 0; } }

@media (min-width: 1200px) {
  .col-xl-2 {
    -webkit-box-flex: 0;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-4 {
    -webkit-box-flex: 0;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-6 {
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-10 {
    -webkit-box-flex: 0;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    -webkit-box-flex: 0;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #161c2d;
  background-color: #fff; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.6em + 1.625rem + 2px);
  padding: 0.8125rem 1.25rem;
  font-size: 1.0625rem;
  font-weight: 400;
  line-height: 1.6;
  color: #161c2d;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #f1f4f8;
  border-radius: 0.375rem;
  box-shadow: none;
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #161c2d; }
  .form-control:focus {
    color: #161c2d;
    background-color: #fff;
    border-color: #a7b9f6;
    outline: 0; }
  .form-control::-webkit-input-placeholder, .form-control::-moz-placeholder, .form-control:-ms-input-placeholder, .form-control::-ms-input-placeholder, .form-control::placeholder {
    color: #869ab8;
    opacity: 1; }
  .form-control:disabled {
    background-color: #f1f4f8;
    opacity: 1; }

@media (prefers-reduced-motion: reduce) {
  .form-control {
    -webkit-transition: none;
    transition: none; } }

.form-group {
  margin-bottom: 1rem; }

.btn {
  display: inline-block;
  font-weight: 600;
  color: #161c2d;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.8125rem 1.25rem;
  font-size: 1.0625rem;
  line-height: 1.6;
  border-radius: 0.375rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .btn:hover {
    color: #161c2d;
    text-decoration: none; }
   .btn:focus {
    outline: 0;
    box-shadow: none; }
   .btn:disabled {
    opacity: 0.65;
    box-shadow: none; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }
    .btn:not(:disabled):not(.disabled).active, .btn:not(:disabled):not(.disabled):active {
      box-shadow: none; }

@media (prefers-reduced-motion: reduce) {
  .btn {
    -webkit-transition: none;
    transition: none; } }

.btn-primary {
  color: #fff;
  background-color: #335eea;
  border-color: #335eea;
  box-shadow: none; }
  .btn-primary:hover {
    color: #fff;
    background-color: #1746e0;
    border-color: #1643d4; }
   .btn-primary:focus {
    color: #fff;
    background-color: #1746e0;
    border-color: #1643d4;
    box-shadow: 0 0 0 0 rgba(82, 118, 237, 0.5); }
   .btn-primary:disabled {
    color: #fff;
    background-color: #335eea;
    border-color: #335eea; }
  .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #1643d4;
    border-color: #153fc9; }

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0 rgba(82, 118, 237, 0.5); }

.btn-outline-primary {
  color: #335eea;
  border-color: #335eea; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #335eea;
    border-color: #335eea; }
   .btn-outline-primary:focus {
    box-shadow: 0 0 0 0 rgba(51, 94, 234, 0.5); }
   .btn-outline-primary:disabled {
    color: #335eea;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #335eea;
    border-color: #335eea; }

.btn-outline-primary:not(:disabled):not(.disabled).active:focus, .btn-outline-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0 rgba(51, 94, 234, 0.5); }

.btn-link {
  font-weight: 400;
  color: #335eea;
  text-decoration: none; }
  .btn-link:hover {
    color: #133bbd;
    text-decoration: underline; }
   .btn-link:focus {
    text-decoration: underline; }
   .btn-link:disabled {
    color: #869ab8;
    pointer-events: none; }

 .btn-sm {
  padding: 0.5625rem 1rem;
  font-size: 1.0625rem;
  line-height: 1.6;
  border-radius: 0.375rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="button"].btn-block, input[type="submit"].btn-block {
  width: 100%; }

.collapse:not(.show) {
  display: none; }

.dropdown, .dropright {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 14rem;
  padding: 1.625rem 0;
  margin: 0 0 0;
  font-size: 0.9375rem;
  color: #161c2d;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(22, 28, 45, 0.15);
  border-radius: 0.375rem;
  box-shadow: 0 0.5rem 1.5rem rgba(22, 28, 45, 0.1); }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0 1.75rem;
  clear: both;
  font-weight: 400;
  color: #506690;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:focus, .dropdown-item:hover, .dropdown-item.active, .dropdown-item:active {
    color: #335eea;
    text-decoration: none;
    background-color: none; }
   .dropdown-item:disabled {
    color: #869ab8;
    pointer-events: none;
    background-color: transparent; }

.dropdown-header {
  display: block;
  padding: 1.625rem 1.75rem;
  margin-bottom: 0;
  font-size: 0.9375rem;
  color: #335eea;
  white-space: nowrap; }

.nav {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:focus, .nav-link:hover {
    text-decoration: none; }

.navbar {
  position: relative;
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 1.25rem 0; }
  .navbar .container, .navbar .container-fluid {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.15rem;
  padding-bottom: 0.15rem;
  margin-right: 0;
  font-size: 1.5rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:focus, .navbar-brand:hover {
    text-decoration: none; }

.navbar-nav {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-collapse {
  flex-basis: 100%;
  -webkit-box-flex: 1;
  flex-grow: 1;
  -webkit-box-align: center;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.1875rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.375rem; }
  .navbar-toggler:focus, .navbar-toggler:hover {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 1.5rem;
        padding-left: 1.5rem; }
    .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: -webkit-box !important;
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

.navbar-light .navbar-brand {
  color: #335eea; }
  .navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
    color: #335eea; }

.navbar-light .navbar-nav .nav-link {
  color: #506690; }
  .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color: #335eea; }

.navbar-light .navbar-nav .active > .nav-link {
  color: #335eea; }

.navbar-light .navbar-nav .nav-link.active {
  color: #335eea; }

.navbar-light .navbar-toggler {
  color: #506690;
  border-color: transparent; }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='%23506690' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.card {
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid #f1f4f8;
  border-radius: 0.375rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.375rem - 0px);
      border-top-right-radius: calc(0.375rem - 0px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.375rem - 0px);
      border-bottom-left-radius: calc(0.375rem - 0px); }

.card-body {
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 2rem; }

.card-header {
  padding: 1.5rem 2rem;
  margin-bottom: 0;
  background-color: rgba(22, 28, 45, 0.03);
  border-bottom: 0 solid #f1f4f8; }
  .card-header:first-child {
    border-radius: calc(0.375rem - 0px) calc(0.375rem - 0px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 1.5rem 2rem;
  background-color: rgba(22, 28, 45, 0.03);
  border-top: 0 solid #f1f4f8; }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.375rem - 0px) calc(0.375rem - 0px); }

.card-img, .card-img-top {
  flex-shrink: 0;
  width: 100%; }

.card-img, .card-img-top {
  border-top-left-radius: calc(0.375rem - 0px);
  border-top-right-radius: calc(0.375rem - 0px); }

.card-img {
  border-bottom-right-radius: calc(0.375rem - 0px);
  border-bottom-left-radius: calc(0.375rem - 0px); }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: 0; }

.badge {
  display: inline-block;
  padding: 0.25rem 0.75rem;
  font-size: 75%;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.375rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .badge {
    -webkit-transition: none;
    transition: none; } }

a.badge:focus, a.badge:hover {
  text-decoration: none; }

.badge:empty {
  display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-info {
  color: #fff;
  background-color: #7c69ef; }

a.badge-info:focus, a.badge-info:hover {
  color: #fff;
  background-color: #543bea; }

 a.badge-info:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(124, 105, 239, 0.5); }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.media {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: start;
  align-items: flex-start; }

.list-group {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.375rem; }

.list-group-item {
  position: relative;
  display: block;
  padding: 1.5rem 2rem;
  background-color: #fff;
  border: 1px solid #f1f4f8; }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
   .list-group-item:disabled {
    color: #869ab8;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #335eea;
    border-color: #335eea; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.carousel {
  position: relative; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    -webkit-transition: none;
    transition: none; } }

 .carousel-item.active {
  display: block; }

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.bg-primary {
  background-color: #335eea !important; }

a.bg-primary:focus, a.bg-primary:hover {
  background-color: #1643d4 !important; }

button.bg-primary:focus, button.bg-primary:hover {
  background-color: #1643d4 !important; }

.bg-light {
  background-color: #f9fbfd !important; }

a.bg-light:focus, a.bg-light:hover {
  background-color: #d3e2f0 !important; }

button.bg-light:focus, button.bg-light:hover {
  background-color: #d3e2f0 !important; }

.bg-dark {
  background-color: #1b2a4e !important; }

a.bg-dark:focus, a.bg-dark:hover {
  background-color: #0e1628 !important; }

button.bg-dark:focus, button.bg-dark:hover {
  background-color: #0e1628 !important; }

.bg-black {
  background-color: #161c2d !important; }

a.bg-black:focus, a.bg-black:hover {
  background-color: #05070b !important; }

button.bg-black:focus, button.bg-black:hover {
  background-color: #05070b !important; }

.bg-white {
  background-color: #fff !important; }

a.bg-white:focus, a.bg-white:hover {
  background-color: #e6e6e6 !important; }

button.bg-white:focus, button.bg-white:hover {
  background-color: #e6e6e6 !important; }

.bg-white {
  background-color: #fff !important; }

.border {
  border: 1px solid #f1f4f8 !important; }

.border-top {
  border-top: 1px solid #f1f4f8 !important; }

.border-bottom {
  border-bottom: 1px solid #f1f4f8 !important; }

.rounded {
  border-radius: 0.375rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.d-none {
  display: none !important; }

.d-block {
  display: block !important; }

.d-flex {
  display: -webkit-box !important;
  display: flex !important; }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-block {
    display: block !important; } }

.justify-content-center {
  -webkit-box-pack: center !important;
  justify-content: center !important; }

.justify-content-between {
  -webkit-box-pack: justify !important;
  justify-content: space-between !important; }

.align-items-center {
  -webkit-box-align: center !important;
  align-items: center !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.shadow {
  box-shadow: 0 0.5rem 1.5rem rgba(22, 28, 45, 0.1) !important; }

.shadow-lg {
  box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.1) !important; }

.w-100 {
  width: 100% !important; }

.h-100 {
  height: 100% !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mr-2 {
  margin-right: 0.5rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.ml-2 {
  margin-left: 0.5rem !important; }

.mr-3 {
  margin-right: 0.75rem !important; }

.mb-3 {
  margin-bottom: 0.75rem !important; }

.mt-4 {
  margin-top: 1rem !important; }

.mr-4 {
  margin-right: 1rem !important; }

.mb-4 {
  margin-bottom: 1rem !important; }

.mt-5 {
  margin-top: 1.5rem !important; }

.mb-5 {
  margin-bottom: 1.5rem !important; }

.mt-6 {
  margin-top: 2rem !important; }

.mb-6 {
  margin-bottom: 2rem !important; }

.mt-7 {
  margin-top: 2.5rem !important; }

.mb-7 {
  margin-bottom: 2.5rem !important; }

.mb-8 {
  margin-bottom: 3rem !important; }

.pt-0 {
  padding-top: 0 !important; }

 .px-0 {
  padding-right: 0 !important; }

.pb-0 {
  padding-bottom: 0 !important; }

 .px-0 {
  padding-left: 0 !important; }

.pb-1 {
  padding-bottom: 0.25rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pr-2, .px-2 {
  padding-right: 0.5rem !important; }

.pb-2 {
  padding-bottom: 0.5rem !important; }

 .px-2 {
  padding-left: 0.5rem !important; }

.pt-4 {
  padding-top: 1rem !important; }

.pt-5 {
  padding-top: 1.5rem !important; }

.pt-6 {
  padding-top: 2rem !important; }

.pr-6 {
  padding-right: 2rem !important; }

 .py-7 {
  padding-top: 2.5rem !important; }

 .py-7 {
  padding-bottom: 2.5rem !important; }

.pt-8, .py-8 {
  padding-top: 3rem !important; }

.pr-8 {
  padding-right: 3rem !important; }

.pb-8, .py-8 {
  padding-bottom: 3rem !important; }

.pr-9 {
  padding-right: 4rem !important; }

.pt-10 {
  padding-top: 5rem !important; }

.pt-11 {
  padding-top: 6rem !important; }

.pt-12 {
  padding-top: 8rem !important; }

.pb-12 {
  padding-bottom: 8rem !important; }

.mt-n1 {
  margin-top: -0.25rem !important; }

.mt-n4 {
  margin-top: -1rem !important; }

.mt-n9 {
  margin-top: -4rem !important; }

.mt-n14 {
  margin-top: -12rem !important; }

.mt-auto {
  margin-top: auto !important; }

.mr-auto {
  margin-right: auto !important; }

.ml-auto {
  margin-left: auto !important; }

@media (min-width: 768px) {
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mr-md-3 {
    margin-right: 0.75rem !important; }
  .mb-md-7 {
    margin-bottom: 2.5rem !important; }
  .mb-md-8 {
    margin-bottom: 3rem !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pr-md-0 {
    padding-right: 0 !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-4 {
    padding-bottom: 1rem !important; }
  .pt-md-6 {
    padding-top: 2rem !important; }
   .py-md-8 {
    padding-top: 3rem !important; }
   .py-md-8 {
    padding-bottom: 3rem !important; }
  .pt-md-10 {
    padding-top: 5rem !important; }
  .pb-md-10 {
    padding-bottom: 5rem !important; }
  .pt-md-11, .py-md-11 {
    padding-top: 6rem !important; }
  .pb-md-11, .py-md-11 {
    padding-bottom: 6rem !important; }
  .pt-md-12 {
    padding-top: 8rem !important; }
  .pb-md-12 {
    padding-bottom: 8rem !important; }
  .pb-md-14 {
    padding-bottom: 12rem !important; }
  .mt-md-n8 {
    margin-top: -3rem !important; } }

@media (min-width: 992px) {
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-7 {
    margin-bottom: 2.5rem !important; }
  .mb-lg-8 {
    margin-bottom: 3rem !important; }
  .pr-lg-0 {
    padding-right: 0 !important; }
   .px-lg-9 {
    padding-right: 4rem !important; }
   .px-lg-9 {
    padding-left: 4rem !important; }
  .pt-lg-10 {
    padding-top: 5rem !important; }
   .px-lg-13 {
    padding-right: 10rem !important; }
   .px-lg-13 {
    padding-left: 10rem !important; } }

@media (min-width: 1200px) {
  .mb-xl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xl-8 {
    margin-bottom: 3rem !important; }
   .px-xl-0 {
    padding-right: 0 !important; }
   .px-xl-0 {
    padding-left: 0 !important; }
  .pr-xl-10 {
    padding-right: 5rem !important; } }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.font-weight-bold {
  font-weight: 600 !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #335eea !important; }

a.text-primary:focus, a.text-primary:hover {
  color: #133bbd !important; }

.text-info {
  color: #7c69ef !important; }

a.text-info:focus, a.text-info:hover {
  color: #4024e8 !important; }

.text-warning {
  color: #fad776 !important; }

a.text-warning:focus, a.text-warning:hover {
  color: #f7c12c !important; }

.text-danger {
  color: #df4759 !important; }

a.text-danger:focus, a.text-danger:hover {
  color: #b92032 !important; }

.text-primary-desat {
  color: #6c8aec !important; }

a.text-primary-desat:focus, a.text-primary-desat:hover {
  color: #2854e3 !important; }

.text-black {
  color: #161c2d !important; }

a.text-black:focus, a.text-black:hover {
  color: #000 !important; }

.text-white {
  color: #fff !important; }

a.text-white:focus, a.text-white:hover {
  color: #d9d9d9 !important; }

.text-muted {
  color: #869ab8 !important; }

.text-decoration-none {
  text-decoration: none !important; }

.text-reset {
  color: inherit !important; }

@media print {
  *, ::after, ::before {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  img {
    page-break-inside: avoid; }
  h2, h3, p {
    orphans: 3;
    widows: 3; }
  h2, h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body, .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #161c2d; }
  .table {
    border-collapse: collapse !important; } }

.badge-primary-soft {
  background-color: rgba(51, 94, 234, 0.1);
  color: #335eea; }

a.badge-primary-soft:focus, a.badge-primary-soft:hover {
  background-color: rgba(51, 94, 234, 0.2);
  color: #335eea; }

.badge-lg {
  padding: 0.7em 1.15em; }

.badge-float {
  position: absolute;
  z-index: 1000; }

.badge-float-outside {
  top: -0.5rem;
  right: -0.5rem; }

.btn > img {
  max-height: 1em;
  width: auto; }

.btn:not([class*="btn-outline"]) {
  border-color: transparent !important; }

.btn-outline-gray-300 {
  border-color: #d9e2ef;
  color: #335eea; }
  .btn-outline-gray-300:focus, .btn-outline-gray-300:hover, .btn-outline-gray-300.active {
    background-color: #335eea;
    border-color: #335eea;
    color: #fff; }

.btn-primary-soft {
  background-color: rgba(51, 94, 234, 0.1);
  color: #335eea; }
  .btn-primary-soft:focus, .btn-primary-soft:hover, .btn-primary-soft.active {
    background-color: rgba(51, 94, 234, 0.15);
    color: #335eea; }

.btn-rounded-circle {
  padding-left: 0.8125rem;
  padding-right: 0.8125rem;
  border-radius: 50rem; }
  .btn-rounded-circle > * {
    display: block;
    line-height: 1;
    width: 1em; }

.btn-rounded-circle.btn-sm {
  padding-left: 0.5625rem;
  padding-right: 0.5625rem; }

.card {
  position: relative;
  width: 100%; }

.card-header {
  background-color: transparent;
  border-bottom: 1px solid #f1f4f8; }

.card-body {
  display: block;
  -webkit-box-flex: 0;
  flex-grow: 0; }

.card-footer {
  background-color: unset; }

.card-body, .card-footer, .card-img, .card-img-top, .card-meta {
  position: relative;
  min-height: 1px; }

a.card, a.card-body, a.card-footer, a.card-meta {
  color: inherit; }

a.card-body:hover, a.card-footer:hover, a.card-meta:hover, a.card:hover {
  text-decoration: none; }

.card-meta {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-align: center;
  align-items: center;
  padding: 0 2rem 1.5rem; }

.card-meta-divider {
  width: 100%;
  margin: 0 0 1.5rem; }

.dropdown-menu {
  padding-left: 1.75rem;
  padding-right: 1.75rem; }

.dropdown-item {
  width: auto;
  min-width: 100%;
  margin-left: -1.75rem;
  margin-right: -1.75rem; }
  .dropdown-item:focus, .dropdown-item:hover {
    outline: 0; }
  .dropdown-item + .dropdown-item {
    margin-top: 0.45rem; }

.dropdown-link {
  color: inherit; }
  .dropdown-link:hover {
    text-decoration: inherit; }
  .dropdown-link:focus, .dropdown-link:hover {
    outline: 0; }

.dropdown-header {
  margin-left: -1.75rem;
  margin-right: -1.75rem;
  padding-top: 0;
  padding-bottom: 0.5rem;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.55;
  text-transform: uppercase;
  letter-spacing: 0.08em; }

.dropdown-toggle::after {
  font-family: Feather;
  vertical-align: middle;
  border: none !important;
  content: "\e92e"; }

.dropright > .dropdown-toggle::after {
  content: "\e930"; }

.dropdown-menu-lg {
  min-width: 35rem; }

.dropdown-menu-lg {
  padding: 2.375rem 2.5rem; }

.dropdown-menu-lg .dropdown-item {
  margin-left: -2.5rem;
  margin-right: -2.5rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem; }

.dropright > .dropdown-menu {
  top: -1.625rem; }

[class^="container"] {
  position: relative;
  z-index: 1; }

.img-fluid > img, .img-fluid > svg {
  max-width: inherit;
  height: inherit; }

.list-group-flush .list-group-item {
  padding-left: 0;
  padding-right: 0; }
  .list-group-flush .list-group-item:first-child {
    padding-top: 0 !important;
    border-top: 0; }
  .list-group-flush .list-group-item:last-child {
    padding-bottom: 0 !important;
    border-bottom: 0; }

.navbar {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-transition-property: background-color, color;
  transition-property: background-color, color;
  z-index: 1030; }
  .navbar > .container, .navbar > .container-fluid {
    padding-left: 20px !important;
    padding-right: 20px !important; }

.navbar-brand {
  font-weight: 600; }

.navbar-brand-img {
  max-height: 2.4rem;
  width: auto; }

@media (max-width: 991.98px) {
  .navbar-btn {
    width: 100%;
    padding: 0.8125rem 1.25rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    font-size: 1.0625rem; } }

.navbar-nav .nav-link {
  font-weight: 600; }
  .navbar-nav .nav-link:focus, .navbar-nav .nav-link:hover {
    outline: 0; }

@media (max-width: 991.98px) {
  .navbar-collapse {
    position: fixed;
    top: 1rem;
    left: 1rem;
    height: auto;
    max-height: calc(100% - 2rem) !important;
    width: calc(100% - 2rem);
    background-color: #fff;
    border-radius: 0.375rem;
    box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.15);
    overflow-x: hidden;
    overflow-y: scroll; } }

.navbar .navbar-toggler {
  margin-left: auto; }

.navbar-collapse .navbar-toggler {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 1; }

@media (min-width: 992px) {
  .navbar-collapse .navbar-toggler {
    display: none; } }

@media (max-width: 991.98px) {
  .navbar-nav .nav-item {
    padding: 1.5rem; }
    .navbar-nav .nav-item + .nav-item {
      border-top: 1px solid #f1f4f8; } }

@media (max-width: 991.98px) {
  .navbar-nav .nav-link {
    padding-top: 0;
    padding-bottom: 0; } }

.navbar-nav .dropdown-menu {
  box-shadow: none; }

.navbar-nav .dropdown > .dropdown-toggle::after {
  display: none; }

.navbar-nav .dropright > .dropdown-toggle {
  display: -webkit-box;
  display: flex; }
  .navbar-nav .dropright > .dropdown-toggle::after {
    margin-left: auto; }

.navbar-nav .dropdown-menu .list-group-item {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: inherit; }
  .navbar-nav .dropdown-menu .list-group-item:hover {
    text-decoration: none; }

@media (max-width: 991.98px) {
  .navbar-nav .dropdown-menu {
    min-width: 0;
    padding: 0; } }

@media (min-width: 992px) {
  .navbar-nav .dropdown-menu {
    box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.15); } }

@media (max-width: 991.98px) {
  .navbar-nav .dropdown > .dropdown-menu {
    display: block !important; } }

@media (max-width: 991.98px) {
  .navbar-nav .dropright > .dropdown-menu {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 0.5rem; } }

@media (max-width: 991.98px) {
  .navbar-nav .dropdown-header, .navbar-nav .dropdown-item {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0; } }

@media (max-width: 991.98px) {
  .navbar-nav .dropdown > .dropdown-toggle {
    margin-bottom: 1.5rem;
    pointer-events: none; } }

@media (max-width: 991.98px) {
  .navbar-nav .dropright > .dropdown-toggle::after {
    content: "\e92e"; }
  .navbar-nav .dropright > .dropdown-toggle[aria-expanded="true"]::after {
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); } }

@media (min-width: 992px) {
  .navbar-nav .dropdown-menu {
    display: none;
    opacity: 0;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    transition-property: opacity, transform, -webkit-transform; }
  .navbar-nav .dropright > .dropdown-menu {
    -webkit-transform: translateY(10px);
    transform: translateY(10px); }
  .navbar-nav .dropdown > .dropdown-menu {
    left: 50%;
    -webkit-transform: translate(-50%, 10px);
    transform: translate(-50%, 10px); } }

@font-face {
  font-family: HKGroteskPro;
  font-weight: 400;
  src: url(~/media/fonts/HK%20Grotesk%20Pro/HKGroteskPro-Regular.woff2) format("woff2"), url(~/media/fonts/HK%20Grotesk%20Pro/HKGroteskPro-Regular.woff) format("woff"); }

@font-face {
  font-family: HKGroteskPro;
  font-weight: 600;
  src: url(~/media/fonts/HK%20Grotesk%20Pro/HKGroteskPro-Medium.woff2) format("woff2"), url(~/media/fonts/HK%20Grotesk%20Pro/HKGroteskPro-Medium.woff) format("woff"); }

.h1, .h2, h1, h2 {
  margin-bottom: 1rem; }

.h1, .h2, .h3, .h4, h1, h2, h3, h4 {
  letter-spacing: -0.01em; }

.h1, h1 {
  line-height: 1.3; }

@media (max-width: 991.98px) {
  .h1, h1 {
    font-size: 1.875rem; } }

.h2, h2 {
  line-height: 1.35; }

@media (max-width: 991.98px) {
  .h2, h2 {
    font-size: 1.5rem; } }

.h3, h3 {
  line-height: 1.4; }

@media (max-width: 991.98px) {
  .h3, h3 {
    font-size: 1.125rem; } }

.h4, h4 {
  line-height: 1.45; }

@media (max-width: 991.98px) {
  .h4, h4 {
    font-size: 1.0625rem; } }

.h6, h6 {
  line-height: 1.55; }

.h6.text-uppercase, h6.text-uppercase {
  letter-spacing: 0.08em; }

 .display-2, .display-3, .display-4 {
  letter-spacing: -0.02em; }

 .display-2, .display-3, .display-4 {
  line-height: 1.2; }

@media (max-width: 991.98px) {
  .display-2 {
    font-size: 2.5rem; } }

@media (max-width: 991.98px) {
  .display-3 {
    font-size: 2.25rem; } }

@media (max-width: 991.98px) {
  .display-4 {
    font-size: 2rem; } }

@media (max-width: 991.98px) {
  .lead {
    font-size: 1.1875rem; } }

.bg-black {
  background-color: #161c2d !important; }

.lift {
  -webkit-transition: box-shadow 0.25s ease, -webkit-transform 0.25s ease;
  transition: box-shadow 0.25s ease, -webkit-transform 0.25s ease;
  transition: box-shadow 0.25s ease, transform 0.25s ease;
  transition: box-shadow 0.25s ease, transform 0.25s ease, -webkit-transform 0.25s ease; }
  .lift:focus, .lift:hover {
    box-shadow: 0 1rem 2.5rem rgba(22, 28, 45, 0.1), 0 0.5rem 1rem -0.75rem rgba(22, 28, 45, 0.1) !important;
    -webkit-transform: translate3d(0, -3px, 0);
    transform: translate3d(0, -3px, 0); }

.lift-lg:focus, .lift-lg:hover {
  box-shadow: 0 2rem 5rem rgba(22, 28, 45, 0.1), 0 0.5rem 1rem -0.75rem rgba(22, 28, 45, 0.05) !important;
  -webkit-transform: translate3d(0, -5px, 0);
  transform: translate3d(0, -5px, 0); }

.center {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

.shadow-light {
  box-shadow: 0 0.5rem 1.5rem rgba(22, 28, 45, 0.05) !important; }

.shadow-light-lg {
  box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.05) !important; }

.font-size-lg {
  font-size: 1.1875rem !important; }

@media (max-width: 991.98px) {
  .font-size-lg {
    font-size: 1.0625rem !important; } }

.text-gray-400 {
  color: #c6d3e6 !important; }

.text-gray-700 {
  color: #506690 !important; }

.text-white-75 {
  color: rgba(255, 255, 255, 0.75) !important; }

.avatar {
  position: relative;
  display: inline-block;
  width: 1.875rem;
  height: 1.875rem;
  font-size: 0.625rem; }
  .avatar:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    background-image: url(~/media/theme/img/masks/avatar-status.svg), url(~/media/theme/img/masks/avatar-group.svg), url(~/media/theme/img/masks/avatar-group-hover.svg), url(~/media/theme/img/masks/avatar-group-hover-last.svg); }

.avatar-img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover; }

.avatar-sm {
  width: 1.5rem;
  height: 1.5rem;
  font-size: 0.5rem; }

.collapse-chevron {
  display: inline-block;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

[aria-expanded="true"] .collapse-chevron {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg); }

.fe-lg {
  font-size: 1.3125rem; }

@media (min-width: 768px) {
  .fe-lg {
    font-size: 1.125rem; } }

.flickity-button {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: calc(1.6em + 1.625rem + 2px);
  height: calc(1.6em + 1.625rem + 2px);
  background-color: #335eea;
  box-shadow: 0 0.5rem 1.5rem rgba(22, 28, 45, 0.05);
  color: #fff; }
  .flickity-button::after, .flickity-button::before {
    font-family: Feather; }
  .flickity-button:focus, .flickity-button:hover {
    background-color: #335eea;
    box-shadow: 0 0.5rem 1.5rem rgba(22, 28, 45, 0.1); }
  .flickity-button.previous {
    left: 0;
    -webkit-transform: translate(calc(-50% + 10px), -50%);
    transform: translate(calc(-50% + 10px), -50%); }
    .flickity-button.previous::before {
      content: "\e910"; }
  .flickity-button.next {
    right: 0;
    -webkit-transform: translate(calc(50% - 10px), -50%);
    transform: translate(calc(50% - 10px), -50%); }
    .flickity-button.next::before {
      content: "\e912"; }

@media (min-width: 768px) {
  .flickity-button.previous {
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); } }

@media (min-width: 768px) {
  .flickity-button.next {
    -webkit-transform: translate(50%, -50%);
    transform: translate(50%, -50%); } }

.flickity-button-icon {
  display: none; }

.bg-dark .footer-brand {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1); }

.icon > svg {
  width: 3rem;
  height: 3rem; }

.icon[class*="text-"] > svg [fill]:not([fill="none"]) {
  fill: currentColor !important; }

.list {
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none; }

.shape {
  position: absolute;
  pointer-events: none; }
  .shape > * {
    display: block; }
  .shape:not([class*="shape-blur"]) {
    overflow: hidden; }
    .shape:not([class*="shape-blur"]) > * {
      -webkit-transform: scale(2);
      transform: scale(2); }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .shape > svg {
    display: none; } }

.shape-bottom {
  right: 0;
  bottom: 0;
  left: 0; }
  .shape-bottom > * {
    -webkit-transform-origin: top center;
    transform-origin: top center; }

.shape-fluid-x > * {
  width: 100%;
  height: auto; }
